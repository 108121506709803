<template>

    <el-alert
        v-if="!getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)"
        :title="$t('noCurrentSchoolyearWarning')"
        type="warning"
        class="mb-5"/>

    <el-row :gutter="40" class="panel-group mb-10" v-if="getSchoolData(SchoolData.NAME.NAME)">
        <el-col :xs="48" :sm="48" :lg="24" class="card-panel-col">
            <el-card class="card-panel" shadow="hover">
                <template #header>
                    <div class="card-header">
                        <b style="font-size: 21px">{{ getSchoolData(SchoolData.NAME.NAME) }}</b>
                        <el-button
                            circle
                            icon="edit"
                            type="primary"
                            @click="$router.push(Routes.SETTINGS.PATH + '/app')"></el-button>
                    </div>
                </template>
                <div>
                    <el-descriptions
                        class="margin-top"
                        :column="3"
                        border>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.PHONE.NAME)">
                            <template #label>
                                <el-icon><Phone/></el-icon>
                                {{ $t('phone') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.PHONE.NAME) }}</small>
                        </el-descriptions-item>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.EMAIL.NAME)">
                            <template #label>
                                <el-icon><Message/></el-icon>
                                {{ $t('email') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.EMAIL.NAME) }}</small>
                        </el-descriptions-item>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.ADDRESS.NAME)">
                            <template #label>
                                <el-icon><Location/></el-icon>
                                {{ $t('address') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.ADDRESS.NAME) }}</small>
                        </el-descriptions-item>

                    </el-descriptions>
                </div>
            </el-card>
        </el-col>
    </el-row>

    <el-alert :title="$t('setSchoolInfoWarning')" type="warning" class="mb-5" v-else />

    <el-row :gutter="40" class="panel-group mb-5">
        <el-col :xs="6" :sm="6" :lg="6" class="card-panel-col">
            <el-card class="card-panel card-panel-item" shadow="hover" @click="$router.push(Routes.BASIC_INFO.PATH + '/schoolyears')">
                <div class="card-panel-icon-wrapper">
                    <count-to :start-val="0" :end-val="schoolyears.length" :duration="2250" separator="." class="card-panel-num"/>
                </div>
                <el-divider></el-divider>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        {{ $t('schoolyears') }}
                    </div>
                </div>
            </el-card>
        </el-col>
        <el-col :xs="6" :sm="6" :lg="6" class="card-panel-col">
            <el-card class="card-panel card-panel-item" shadow="hover" @click="$router.push(Routes.BASIC_INFO.PATH + '/levels')">
                <div class="card-panel-icon-wrapper">
                    <count-to :start-val="0" :end-val="levels.length" :duration="2500" separator="." class="card-panel-num" />
                </div>
                <el-divider></el-divider>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        {{ $t('levelsNumber') }}
                    </div>
                </div>
            </el-card>
        </el-col>
        <el-col :xs="6" :sm="6" :lg="6" class="card-panel-col">
            <el-card class="card-panel card-panel-item" shadow="hover" @click="$router.push(Routes.BASIC_INFO.PATH + '/classes')">
                <div class="card-panel-icon-wrapper">
                    <count-to :start-val="0" :end-val="classes.length" :duration="2750" separator="." class="card-panel-num" />
                </div>
                <el-divider></el-divider>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        {{ $t('classesNumber') }}
                    </div>
                </div>
            </el-card>
        </el-col>
        <el-col :xs="6" :sm="6" :lg="6" class="card-panel-col">
            <el-card class="card-panel card-panel-item" shadow="hover" @click="$router.push(Routes.BASIC_INFO.PATH + '/courses')">
                <div class="card-panel-icon-wrapper">
                    <count-to :start-val="0" :end-val="courses.length" :duration="3000" separator="." class="card-panel-num" />
                </div>
                <el-divider></el-divider>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        {{ $t('coursesNumber') }}
                    </div>
                </div>
            </el-card>
        </el-col>
    </el-row>

    <el-row :gutter="40" class="panel-group mb-10">
        <el-col :xs="6" :sm="6" :lg="6">
            <el-card class="card-panel card-panel-item" shadow="hover" @click="$router.push(Routes.BASIC_INFO.PATH + '/departments')">
                <div class="card-panel-icon-wrapper">
                    <count-to :start-val="0" :end-val="departments.length" :duration="2250" separator="." class="card-panel-num"/>
                </div>
                <el-divider></el-divider>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        {{ $t('departmentsNumber') }}
                    </div>
                </div>
            </el-card>
        </el-col>
        <el-col :xs="6" :sm="6" :lg="6" class="card-panel-col">
            <el-card class="card-panel card-panel-item" shadow="hover" @click="$router.push(Routes.BASIC_INFO.PATH + '/parents')">
                <div class="card-panel-icon-wrapper">
                    <count-to :start-val="0" :end-val="parents.length" :duration="2500" separator="." class="card-panel-num" />
                </div>
                <el-divider></el-divider>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        {{ $t('parentsNumber') }}
                    </div>
                </div>
            </el-card>
        </el-col>
        <el-col :xs="6" :sm="6" :lg="6" class="card-panel-col">
            <el-card class="card-panel card-panel-item" shadow="hover" @click="$router.push(Routes.BASIC_INFO.PATH + '/students')">
                <div class="card-panel-icon-wrapper">
                    <count-to :start-val="0" :end-val="students.length" :duration="2750" separator="." class="card-panel-num" />
                </div>
                <el-divider></el-divider>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        {{ $t('studentsNumber') }}
                    </div>
                </div>
            </el-card>
        </el-col>
        <el-col :xs="6" :sm="6" :lg="6" class="card-panel-col">
            <el-card class="card-panel card-panel-item" shadow="hover" @click="$router.push(Routes.BASIC_INFO.PATH + '/teachers')">
                <div class="card-panel-icon-wrapper">
                    <count-to :start-val="0" :end-val="teachers.length" :duration="3000" separator="." class="card-panel-num" />
                </div>
                <el-divider></el-divider>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        {{ $t('teachersNumber') }}
                    </div>
                </div>
            </el-card>
        </el-col>
    </el-row>

    <el-row :gutter="40" class="panel-group mb-5" v-if="false">
        <el-col :xs="48" :sm="48" :lg="24" class="card-panel-col">
            <el-card class="card-panel">
                <template #header>
                    <div class="card-header">
                        {{ $t('reportGenPerYear') }}
                    </div>
                </template>
                <div v-if="!isLoadingData">

                    <LineChartWrapper
                        :labels="chartData.labels"
                        :data-list="chartData.dataList"
                        :bg-color="$_.shuffle(availableColors)[0]"/>

                </div>
            </el-card>
        </el-col>
    </el-row>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { Settings, SchoolData, Routes } from '@/utils/enums'

import LineChartWrapper from '@/components/widgets/LineChartWrapper.vue'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'Dashboard',
    mixins: [accessManager],
    components: {
        LineChartWrapper
    },
    data() {
        return {
            departments: [],
            teachers: [],
            students: [],
            parents: [],
            courses: [],
            classes: [],
            levels: [],

            SchoolData,
            Settings,
            Routes,

            chartData: {
                labels: [],
                dataList: []
            },
            availableColors: [
                '#FF6900',
                '#FCB900',
                '#7BDCB5',
                '#00D084',
                '#8ED1FC',
                '#0693E3',
                '#ABB8C3',
                '#EB144C',
                '#F78DA7',
                '#9900EF',
                '#6A737C',
                '#474747',
                '#0000FF'
            ],
            isLoadingData: true
        }
    },
    computed: {
        ...mapState({
            userData: state => state.session.userData,
            schoolyears: state => state.schoolyears.schoolyears
        }),
        ...mapGetters({
            getSettingValue: 'settings/getSettingValue',
            getSchoolData: 'schooldata/getSchoolDataValue'
        })
    },
    async created() {

        await this.fetchDepartments()
        await this.fetchTeachers()
        await this.fetchStudents()
        await this.fetchCourses()
        await this.fetchClasses()
        await this.fetchParents()
        await this.fetchLevels()

        this.loadLineChart()

    },
    methods: {
        ...mapActions({
            fetchDepartmentsDB: 'departments/fetchDepartmentsDB',
            fetchTeachersDB: 'teachers/fetchTeachersDB',
            fetchStudentsDB: 'students/fetchStudentsDB',
            fetchCoursesDB: 'courses/fetchCoursesDB',
            fetchClassesDB: 'classes/fetchClassesDB',
            fetchParentsDB: 'parents/fetchParentsDB',
            fetchLevelsDB: 'levels/fetchLevelsDB',
        }),
        async fetchDepartments(){

            let resp = await this.fetchDepartmentsDB({
                action: 'getDepartments',
                params: {
                    schoolyear: this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)
                }
            })

            if (resp.success){

                if (resp.data) this.departments = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

        },
        async fetchClasses(){

            let resp = await this.fetchClassesDB({
                action: 'getClasses',
                params: {
                    schoolyear: this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)
                }
            })

            if (resp.success){

                if (resp.data) this.classes = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

        },
        async fetchCourses(){

            let resp = await this.fetchCoursesDB({
                action: 'getCourses',
                params: {
                    schoolyear: this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)
                }
            })

            if (resp.success){

                if (resp.data) this.courses = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

        },
        async fetchLevels(){

            let resp = await this.fetchLevelsDB({
                action: 'getLevels',
                params: {
                    schoolyear: this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)
                }
            })

            if (resp.success){

                if (resp.data) this.levels = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

        },
        async fetchTeachers(){

            let resp = await this.fetchTeachersDB({
                action: 'getTeachers',
                params: {
                    schoolyear: this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)
                }
            })

            if (resp.success){

                if (resp.data) this.teachers = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

        },
        async fetchParents(){

            let resp = await this.fetchParentsDB({
                action: 'getParents',
                params: {
                    schoolyear: this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)
                }
            })

            if (resp.success){

                if (resp.data) this.parents = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

        },
        async fetchStudents(){

            let resp = await this.fetchStudentsDB({
                action: 'getStudents',
                params: {
                    schoolyear: this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)
                }
            })

            if (resp.success){

                if (resp.data) this.students = resp.data

            }else{

                this.$message.error(this.$t('anErrorOccured'))

            }

        },
        loadLineChart() {

            for (let index = 0; index < this.schoolyears.length; index++) {
                this.chartData.labels.push(this.schoolyears[index].code)
                this.chartData.dataList.push(0)
            }

            this.isLoadingData = false

        }
    }
}
</script>

<style scoped>

    .card-panel {
        cursor: pointer;
        font-size: 16px;
        color: #666;
        background: #fff;
    }

    .card-panel-item{
        background: whitesmoke;
    }

    .card-panel-description{
        padding-top: 5px;
        margin-top: 10px;
    }

    .card-panel-num {
        float: right;
        font-weight: bold;
        font-size: 21px;
    }

    .card-header, .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-panel-text{
        font-size: 14px;
    }

</style>